import React from 'react';

import { graphql } from 'gatsby';
import { PageBuilderFragment as pageBuilder } from '../query_fragments';

import PageSEO from '@components/SEO';
import Hero from '@components/hero';
import PageBuilder from '@components/pageBuilder';

const Page = ({ uri, data }) => {
	const { seo, isFrontPage, template } = data?.wpPage;
	const heroProps = template?.Hero;
	const components = template?.pageBuilder?.pageBuilder;

	return (
		<>
			{seo && <PageSEO {...seo} />}
			{heroProps && <Hero {...heroProps} isFrontPage={isFrontPage} />}
			<div className='splodges'>
				<PageBuilder components={components} />
			</div>
		</>
	);
};

export const query = graphql`
	query page($uri: String) {
		wpPage(uri: { eq: $uri }, status: { eq: "publish" }) {
			uri
			id
			title
			isFrontPage
			seo {
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				canonical
				opengraphTitle
				opengraphDescription
				opengraphType
				opengraphUrl
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			template {
				...pageBuilder
			}
		}
	}
`;

export default Page;
